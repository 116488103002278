import * as React from "react";
import HelloIamLincey from "../components/IndexMenu";
import AboutWorkContact from "../components/about-work-contact";

export default function IndexPage() {
  return (
    <div>
      <HelloIamLincey></HelloIamLincey>
      <AboutWorkContact></AboutWorkContact>
    </div>
  );
}
